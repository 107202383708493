import { ApplicationType } from '../../app/calculations/model/application-type';
import { CalculationType } from '../../app/calculations/model/calculation-type';
import { DieSize } from '../../app/calculations/model/die-size';
import { Geometry } from '../../app/calculations/model/geometry';
import { Material } from '../../app/calculations/model/material';
import { PunchSize } from '../../app/calculations/model/punch-size';

export default {
  XGUIDE: {
    DASHBOARD: {
      GETTING_STARTED: {
        TITLE: 'TITLE',
        TAKE_THE_TOUR: 'TAKE_THE_TOUR',
      },
    },
    MEDIA: {
      VIDEO_NOT_SUPPORTED: 'VIDEO_NOT_SUPPORTED',
    },
    PRIVACY: {
      TITLE: 'TITLE',
      PARAGRAPH1: 'PARAGRAPH1',
      CHANGE_SETTINGS_LINK: 'CHANGE_SETTINGS_LINK',
      PARAGRAPH2: 'PARAGRAPH2',
      PRIVACY_POLICY: 'PRIVACY_POLICY',
      PRIVACY_POLICY_HREF: 'PRIVACY_POLICY_HREF',
      IMPRINT: 'IMPRINT',
      DETAILED: {
        PARAGRAPH1: 'PARAGRAPH1',
        PARAGRAPH2: 'PARAGRAPH2',
        PARAGRAPH3: 'PARAGRAPH3',
        ALLOW_COOKIES_LABEL: 'ALLOW_COOKIES_LABEL',
        FURTHER_DETAILS: 'FURTHER_DETAILS',
      },
    },
    DIALOG: {
      OK: 'OK',
      SAVE: 'SAVE',
      BACK: 'BACK',
      NEXT: 'NEXT',
      CANCEL: 'CANCEL',
      FINISH: 'FINISH',
    },
    MENU: { CONTACT_US_BUTTON: 'CONTACT_US_BUTTON' },
    MY_TRUMPF: {
      MY_DATA: 'MY_DATA',
      MY_DATA_URL: 'MY_DATA_URL',
      MY_MACHINES: 'MY_MACHINES',
      MY_MACHINES_URL: 'MY_MACHINES_URL',
    },
  },
  ENUMS: {
    SYSTEM_OF_UNITS: {
      METRIC: 'METRIC',
      IMPERIAL: 'IMPERIAL',
    },
    CURRENCY: {
      EURO: 'EURO',
      USDOLLAR: 'USDOLLAR',
    },
  },
  TEMPLATES: {
    HINT: 'HINT',
  },
  UNSUPPORTED_BROWSER: {
    TITLE: 'TITLE',
    MESSAGE: 'MESSAGE',
  },
  DIALOG: {
    BACK: 'BACK',
    NEXT: 'NEXT',
    CANCEL: 'CANCEL',
    FINISH: 'FINISH',
    OK: 'OK',
    SAVE: 'SAVE',
  },
  MAIN_MENU: {
    WELDING_PRINCIPLES: 'WELDING_PRINCIPLES',
    PARTS: 'PARTS',
    CALCULATIONS: 'CALCULATIONS',
  },
  DASHBOARD: {
    GETTING_STARTED: {
      TOUR_VIDEO_URL: 'TOUR_VIDEO_URL',
    },
    WELCOME: {
      DESCRIPTION: 'DESCRIPTION',
    },
    NEWS: {
      TITLE: 'TITLE',
      NO_NEWS: 'NO_NEWS',
    },
  },
  ABOUT: {
    IMPRINT: 'IMPRINT',
    DATA_PRIVACY: 'DATA_PRIVACY',
    COPYRIGHT: 'COPYRIGHT',
    SETTINGS: 'SETTINGS',
  },
  SETTINGS: {
    LANGUAGE: 'LANGUAGE',
    UNIT_SYSTEM: 'UNIT_SYSTEM',
    CURRENCY: 'CURRENCY',
  },
  IMPRINT: {
    OPERATOR: 'OPERATOR',
    MANAGEMENT: 'MANAGEMENT',
    REGISTER_COURT: 'REGISTER_COURT',
  },
  DATA_PRIVACY: {
    TITLE: 'TITLE',
    PARAGRAPH1: 'PARAGRAPH1',
    PARAGRAPH2: 'PARAGRAPH2',
    PARAGRAPH3: 'PARAGRAPH3',
    LINK_URL: 'LINK_URL',
    LINK_TITLE: 'LINK_TITLE',
    AFTER_LINK_TEXT: 'AFTER_LINK_TEXT',
  },
  COPYRIGHT: {
    TITLE: 'TITLE',
    PARAGRAPH1: 'PARAGRAPH1',
    PARAGRAPH2: 'PARAGRAPH2',
  },
  PARTS: {
    MENU: {
      ALL: 'ALL',
    },
    SPECIFICATION: {
      PARTS_COUNT: 'PARTS_COUNT',
      JOINTS_COUNT: 'JOINTS_COUNT',
      BENDINGS_COUNT: 'BENDINGS_COUNT',
    },
    FILTERS: {
      REQUIREMENTS: 'REQUIREMENTS',
      SEAM_GEOMETRY: 'SEAM_GEOMETRY',
      MATERIAL: 'MATERIAL',
      SHEET_THICKNESS: 'SHEET_THICKNESS',
    },
    CLEAR_ALL_TAGS: 'CLEAR_ALL',
    RELATED_PARTS: 'RELATED_PARTS',
    DOWNLOAD_CAD: 'DOWNLOAD_CAD',
    CALCULATION: 'CALCULATION',
    RELATED_WELDING_PRINCIPLES_TITLE: 'RELATED_WELDING_PRINCIPLES_TITLE',
    FIXTURE: {
      ALTERNATIVE: 'ALTERNATIVE',
      PREBINDERS: 'PREBINDERS',
      FIXTURE_FOR: 'FIXTURE_FOR',
    },
  },
  WELDING_PRINCIPLES: {
    DETAILS: {
      MORE_PARTS: 'MORE_PARTS',
      HELP: {
        TITLE: 'TITLE',
        PARAGRAPH_1: 'PARAGRAPH_1',
        PARAGRAPH_2: 'PARAGRAPH_2',
        LINK_HOMEPAGE: 'LINK_HOMEPAGE',
        LINK_HOMEPAGE_URL: 'LINK_HOMEPAGE_URL',
        MAIL_SUBJECT: 'MAIL_SUBJECT',
      },
    },
  },
  SWITCH_COMPONENT: {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
  },
  CALCULATIONS: {
    TYPES: {
      PUNCHING_FORCE: CalculationType.PUNCHING_FORCE.toString(),
      CUTTING_CLEARANCE: CalculationType.CUTTING_CLEARANCE.toString(),
      PRE_PUNCHING_DIAMETER: CalculationType.PRE_PUNCHING_DIAMETER.toString(),
      MAXIMUM_EDGE_LENGTH: CalculationType.MAXIMUM_EDGE_LENGTH.toString(),
      STRIPPER_SELECTION: CalculationType.STRIPPER_SELECTION.toString(),
      SHEET_THICKNESS: CalculationType.SHEET_THICKNESS.toString(),
      SHEET_WEIGHT: CalculationType.SHEET_WEIGHT.toString(),
    },
    INPUT_LABELS: {
      MACHINE: 'MACHINE',
      MATERIAL: 'MATERIAL',
      SHEET_THICKNESS: 'SHEET_THICKNESS',
      DIMENSIONS: 'DIMENSIONS',
      CALCULATE: 'CALCULATE',
      APPLICATION: 'APPLICATION',
      THREAD_SIZE: 'THREAD_SIZE',
      PUNCH_TYPE: 'PUNCH_TYPE',
      PUNCH_GEOMETRY: 'PUNCH_GEOMETRY',
      GAUGE: 'GAUGE',
      LENGTH: 'LENGTH',
      WIDTH: 'WIDTH',
      PUNCH_SIZE: 'PUNCH_SIZE',
      SHEAR: 'SHEAR',
      SIZE: 'SIZE',
      TOOL_LENGTH: 'TOOL_LENGTH',
    },
    ERROR_MESSAGES: {
      PUNCHING_FORCE_TOO_HIGH: 'PUNCHING_FORCE_TOO_HIGH',
      MACHINE_LIMIT_EXCEEDED: 'MACHINE_LIMIT_EXCEEDED',
      PUNCH_NOT_AVAILABLE: 'PUNCH_NOT_AVAILABLE',
      DIE_NOT_AVAILABLE: 'DIE_NOT_AVAILABLE',
    },
    MATERIAL: {
      STEEL: Material.STEEL.toString(),
      STAINLESS_STEEL: Material.STAINLESS_STEEL.toString(),
      ALUMINUM: Material.ALUMINUM.toString(),
    },
    GEOMETRY: {
      ROUND: Geometry.ROUND.toString(),
      SQUARE: Geometry.SQUARE.toString(),
      RECTANGLE: Geometry.RECTANGLE.toString(),
      OBLONG: Geometry.OBLONG.toString(),
    },
    SHEAR_FACTOR: {
      FLAT: 'FLAT',
      WHISPER: 'WHISPER',
      ROOF: 'ROOF',
    },
    PUNCH_SIZE: {
      MULTI_TOOL_5_STATION: PunchSize.MULTI_TOOL_5_STATION.toString(),
      MULTI_TOOL_10_STATION: PunchSize.MULTI_TOOL_10_STATION.toString(),
      SIZE_0_6MM: PunchSize.SIZE_0_6MM.toString(),
      SIZE_0_10POINT5MM: PunchSize.SIZE_0_10POINT5MM.toString(),
      SIZE_1: PunchSize.SIZE_1.toString(),
      SIZE_2: PunchSize.SIZE_2.toString(),
      SIZE_2_ENFORCED_SHOULDER: PunchSize.SIZE_2_ENFORCED_SHOULDER.toString(),
      NOT_POSSIBLE: PunchSize.NOT_POSSIBLE.toString(),
    },
    DIE_SIZE: {
      SIZE_1: DieSize.SIZE_1.toString(),
      SIZE_2: DieSize.SIZE_2.toString(),
      SIZE_2_ENFORCED: DieSize.SIZE_2_ENFORCED.toString(),
      NOT_POSSIBLE: DieSize.NOT_POSSIBLE.toString(),
    },
    CALCULATED_PUNCHING_FORCE_BY_SHEAR: 'CALCULATED_PUNCHING_FORCE_BY_SHEAR',
    RECOMMENDATION_TRUMPF: 'RECOMMENDATION_TRUMPF',
    PUNCH: 'PUNCH',
    DIE: 'DIE',
    RESULT_LABELS: {
      CUTTING_CLEARANCE: 'CUTTING_CLEARANCE',
      MAXIMUM_EDGE_LENGTH: 'MAXIMUM_EDGE_LENGTH',
      MAXIMUM_OUTER_CIRCLE_DIAMETER: 'MAXIMUM_OUTER_CIRCLE_DIAMETER',
      CALCULATED_LENGTHS: 'CALCULATED_LENGTHS',
      SHEET_WEIGHT: 'SHEET_WEIGHT',
    },
    APPLICATION: {
      COUNTERSINK_UPPER: ApplicationType.COUNTERSINK_UPPER.toString(),
      COUNTERSINK_UNDER: ApplicationType.COUNTERSINK_UNDER.toString(),
      TAPPING_EVEN: ApplicationType.TAPPING_EVEN.toString(),
      EXTRUSION: ApplicationType.EXTRUSION.toString(),
    },
    CHECK_SHEET_THICKNESS_MACHINE_WARNING: 'CHECK_SHEET_THICKNESS_MACHINE_WARNING',
    OUTER_DIAMETER: 'OUTER_DIAMETER',
    WRONG_SIZE_ERROR: 'WRONG_SIZE_ERROR',
  },
};
