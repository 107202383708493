export const environment = {
  production: true,
  mock: false,
  mockLogin: false,
  apiUrl: 'https://punchguide.trumpf.com/api',

  // OIDC Settings
  oidcIssuer: 'https://identity.trumpf.com:443/oauth2/token',
  oidcClientId: 'DhCkSRHxy8yKe7guBygYErBfWbAa',
  oidcRedirectUri: window.location.origin,
  oidcScope: 'openid profile given_name family_name locality groups',

  // Cad Api
  cadApiUrl: 'https://dev.drawingconverter.api.trumpf.com/solidconversion/part/v1',
};
