import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  DialogService, mailToLink,
  UsageTrackingPermissionService,
  UsageTrackingService,
} from '@trumpf-xguide/xguide';
import { i18nProvider } from '@trumpf/ux.i18n';
import { MenuItemType } from '@trumpf/ux.ui-community';
import { Observable, Subscription } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { NavigationUsageTracking, UNKNOWN, UsageTrackingMessageType } from '../../../shared';
import { generateNavigationMenu, navigateTo, NavigationSlugs } from './shared/helpers/app-header.helper';
import { TranslationHelper } from './shared/helpers/translation.helpers';
import { IsSupportedBrowserToken } from './shared/injection-tokens/is-supported-browser.injection.token';
import { AuthService } from './shared/services/auth/auth.service';
import { HmiService } from './shared/services/hmi/hmi.service';
import { LanguageService } from './shared/services/language/language.service';
import { UiElementIds } from './shared/usage-tracking/ui-element-ids';
import { UnsupportedBrowserComponent } from './views/unsupported-browser/unsupported-browser.component';

const mailTo = 'teilegestaltung@trumpf.com';
const mailToCc = 'Weld.Guide@de.trumpf.com';
const mailToSubject = 'PunchGuide-Request';

@Component({
  selector: 'lsb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('mailAnchor') mailAnchor?: ElementRef;
  public navigationData: MenuItemType[];
  public guideNavigationData: MenuItemType[];
  public readonly uiElementIds = UiElementIds;
  public isLoggedIn$: Observable<boolean>;
  public userProfile: { displayName: string } | null;
  public isDialogActive$ = this.dialog.isActive;
  public get currentLanguageCode(): string {
    const languageCode = this.languageService.currentLanguage.toString();
    return languageCode.slice(0, 2).toUpperCase();
  }

  public get mailLink(): string {
    return mailToLink(mailTo, mailToCc, mailToSubject);
  }

  private routerEvents = this.router.events.pipe(
    filter((routerEvent): routerEvent is NavigationEnd => routerEvent instanceof NavigationEnd),
  );
  private subscriptions = new Subscription();

  constructor(
    private authService: AuthService,
    private router: Router,
    private usageTrackingPermission: UsageTrackingPermissionService,
    private usageTrackingService: UsageTrackingService,
    private languageService: LanguageService,
    public translations: TranslationHelper,
    private dialog: DialogService,
    @Inject(IsSupportedBrowserToken) public isSupportedBrowser: boolean,
    public hmiService: HmiService,
    private translateService: TranslateService,
  ) {
    this.initAuthentication();
    this.sendApplicationStartUsageTracking();
    this.initUsageTrackingService();
    this.languageService.initLanguage();
    this.initTrumpfI18Next();
    this.initTrumpfCookieConsentLanguage();
    this.initXGuideCookiePermission();
    this.subscribeForCookieSettingsChange();
    this.initGuideNavigationData();

    this.authService.userProfile$.subscribe((user) => {
      this.userProfile = user
        ? {
          displayName: user?.givenName + ' ' + user?.familyName,
        }
        : null;
    });
  }

  async ngOnInit() {
    if (!this.isSupportedBrowser) {
      await this.dialog.open(UnsupportedBrowserComponent);
    }

    // This subscription reloads nav bar menu each time when language changed
    const languageSubscription = this.translateService
      .stream(this.translations.MAIN_MENU.WELDING_PRINCIPLES)
      .subscribe((_) => {
        this.navigationData = generateNavigationMenu(this.translateService, this.translations);
      });
    this.subscriptions.add(languageSubscription);
  }

  onUicMenuChange(event: Event) {
    const slug = (event as CustomEvent).detail.current as NavigationSlugs;

    if (slug === NavigationSlugs.LOGIN) {
      this.login();
    }
    if (slug === NavigationSlugs.MAIL_TO) {
      this.mailAnchor?.nativeElement.dispatchEvent(new MouseEvent('click'));
    }

    navigateTo(slug, this.router, this.usageTrackingService);
  }

  private initAuthentication() {
    this.isLoggedIn$ = this.authService.isAuthenticated$;

    this.authService.runInitialLoginSequence().catch((e) => console.log(e));
  }

  /**
   * @deprecated XGuide cookie service is not used.
   * https://d2b5ydludecfwg.cloudfront.net/?path=/story/introduction-introduction--page used instead
   */
  private initPrivacyPopup() {
    const privacyPopupUrlSubscription = this.routerEvents.subscribe((event) => {
      const url = event.urlAfterRedirects;
      this.usageTrackingPermission.closePrivacySettingsPopup();

      if (url.includes('/about/')) {
        // closing the popup(s) to let the user see the /about/* pages, incl. settings, privacy, etc.
        this.usageTrackingPermission.closeUserPermissionPopup();
        return;
      }

      if (!this.usageTrackingPermission.userAlreadyAsked) {
        this.usageTrackingPermission.askUserForPermission();
      }
    });

    this.subscriptions.add(privacyPopupUrlSubscription);
  }

  /**
   * We need this method to propagate our language settings to app header menu
   */
  private initTrumpfI18Next() {
    i18nProvider.initI18n({ lng: this.languageService.currentLanguage.toString() }).then(() => {
      const languageSubscription = this.languageService.currentLanguage$.subscribe((language) => {
        i18nProvider.i18n.changeLanguage(language.toString()).then();
      });
      this.subscriptions.add(languageSubscription);
    });
  }

  /**
   * Trumpf UX UI cookie consent dialog setup.
   */
  private initTrumpfCookieConsentLanguage() {
    // NOTE: Trumpf UX UI cookie consent works as the pure javascript function which attached in the index.html.
    // So, here we are accessing it via 'window'
    (window as any).trumpf.ux.uiCookieConsent.init({
      locale: this.languageService.currentLanguage.toString(),
      theme: 'web',
    });
  }

  /**
   * This method reads user's consent from Trumpf's cookie storage and sets it into the XGuide's cookie storage is it is not set.
   * TL DR...
   * To send usage tracking data to the Trumpf Elastic server, we need user's permission.
   * We have implemented our own mechanism with dialog and user's consent storage in the cookies.
   * This mechanism was encapsulated in the XGuide as well as mechanism of sending usage tracking messages.
   * So, each time when we call `this.usageTrackingService.send()`, this method internally checks user's consent
   * in the XGuide cookie storage.
   * But now we are using the Trumpf cookie mechanism which stores user's consent in its own cookie storage.
   * We need this method to support the old mechanism of sending usage tracking messages.
   */
  private initXGuideCookiePermission() {
    const hasConsentedOptional = !!(window as any).trumpf.ux.uiCookieConsent.hasConsented(
      'optional',
    );
    this.setXGuideCookieSetting(hasConsentedOptional);
  }

  /**
   * User can open privacy settings and change cookie policy. We need to adjust user's choice in the XGuide cookie storage
   */
  private subscribeForCookieSettingsChange() {
    window.addEventListener('uxCookieSettings', (event) => {
      const hasConsentedOptional = !!(window as any).trumpf.ux.uiCookieConsent.hasConsented(
        'optional',
      );
      this.setXGuideCookieSetting(hasConsentedOptional);
    });
  }

  /**
   * Trumpf cookie storage has optional cookie parameter. This parameter we are translating to XGuide cookie storage
   * which allows on not allows data usage tracking
   */
  private setXGuideCookieSetting(hasConsentedOptional: boolean) {
    const permission = hasConsentedOptional ? 'accepted' : 'denied';
    this.usageTrackingPermission.setPermission(permission);
  }

  private sendApplicationStartUsageTracking() {
    const applicationStartUsageTracking = this.generateNavigateUsageTracking(
      document.referrer,
      this.router.url,
    );

    this.usageTrackingService.send(
      UsageTrackingMessageType.Navigation,
      applicationStartUsageTracking,
    );
  }

  @HostListener('window:beforeunload')
  endApplicationStartUsageTracking(event: Event) {
    const nextUrl = (event as any)?.href || (document.activeElement as any).href || UNKNOWN;

    const applicationStartUsageTracking = this.generateNavigateUsageTracking(
      this.router.url,
      nextUrl,
    );

    this.usageTrackingService.send(
      UsageTrackingMessageType.Navigation,
      applicationStartUsageTracking,
    );
  }

  private initUsageTrackingService() {
    const routerSubscription = this.routerEvents
      .pipe(pairwise())
      .subscribe(([previousRoutingEvent, currentRoutingEvent]) => {
        const navigationUsageTracking = this.generateNavigateUsageTracking(
          previousRoutingEvent.urlAfterRedirects,
          currentRoutingEvent.urlAfterRedirects,
        );

        this.usageTrackingService.send(
          UsageTrackingMessageType.Navigation,
          navigationUsageTracking,
        );
      });

    this.subscriptions.add(routerSubscription);
  }

  private generateNavigateUsageTracking(
    previousUrl: string,
    currentUrl: string,
  ): NavigationUsageTracking {
    return {
      currentUrl,
      previousUrl,
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public login() {
    this.authService.login();
  }

  public logout(_: Event) {
    this.authService.logout();
  }

  private initGuideNavigationData(): void {
    this.guideNavigationData = [
      {
        icon: 'punch-guide-icons:Machine.Generic.2',
        label: '2D LaserGuide',
        slug: '2dlaserguide',
        href: 'https://2dlaserguide.trumpf.com/#/dashboard',
      },
      {
        icon: 'punch-guide-icons:TotalPunch',
        label: 'PunchGuide',
        slug: 'punchguide',
        href: '#/dashboard',
      },
      {
        icon: 'punch-guide-icons:Bending.Machine.Side.Bent.Sheets.Parallel',
        label: 'BendGuide',
        slug: 'bendguide',
        href: 'https://bendguide.trumpf.com/#/dashboard',
      },
      {
        icon: 'punch-guide-icons:BendingMachine.Frontal.Bulb.Lit',
        label: 'WeldGuide',
        slug: 'weldguide',
        href: 'https://weldguide.trumpf.com/#/dashboard',
      },
      {
        icon: 'punch-guide-icons:Optimate',
        label: 'Optimate',
        slug: 'optimate',
        href: 'https://www.optimate.de/en/',
        target: '_blank',
      },
      {
        icon: 'punch-guide-icons:Network.Cube',
        label: 'MyTRUMPF',
        slug: 'mytrumpf',
        href: 'https://www.trumpf.com/de_DE/mytrumpf/startseite/',
        target: '_blank',
      },
      {
        icon: 'punch-guide-icons:Cart.Cogs',
        label: 'Shop',
        slug: 'shop',
        href: 'https://trushop.my.trumpf.com/de_DE/',
        target: '_blank',
      },
    ];
  }

}
